/** Space for all imported CSS Libraries **/

@use '@aegis-framework/kayros.css';

@use 'animate.css';
@use './../node_modules/@fortawesome/fontawesome-free/css/svg-with-js.css';


/** Space for all imported Fonts **/


/** Main Style goes here**/

html {
  width: 100dvw;
  height: 100dvh;
}

h1 {
	font-weight: lighter;
  font-family: 'Open Sans', sans-serif;
}

small {
	display: block;
	font-size: 0.8rem
}

body {
  background-color: #292841;
  flex-direction: column;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  max-height: 100%;
  overflow: auto;

  color: #fff;
}

button {
  box-shadow: 6.01px 6.01px 9px rgba(0, 0, 0, 0.1), -6.01px -6.01px 9px rgba(0, 0, 0, 0.1);
  background: #2E2D49;
  color: #fff;

  font-size: 0.8rem;
  border-radius: 8px;

  &:hover,
  &.active {
    background: rgba(0, 0, 0, 0.2);
  }
}

.header {
  margin: 2rem 0;

  & > div {
    background: #2E2D49;
    border-radius: 100%;
    box-shadow: inset 30px 30px 15px #242339, inset -30px -30px 15px #2E2D49;
    height: 150px;
    width: 150px;

    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 85%;
      max-height: 85%;
      border-radius: 100%;
    }
  }

  svg {
    height: 80px;
    max-height: 80px;
    fill: #fff;
    color: #fff;
  }
}


h2 {
  font-weight: lighter;
  margin-bottom: 1rem;
  border-bottom: 1px solid #fff;
  padding-bottom: 0.5rem;
  display: inline-block;
}

h3 {
  font-weight: lighter;
  font-size: 1.4rem;
  margin: 1rem 0;
}

.discord-slash-commands,
.discord-chat-commands,
.twitch-chat-commands {
  // max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin: 0 auto;

  padding: 1rem 0;
}

.discord-slash-tags,
.discord-chat-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.2);

  padding: 1rem;

  box-shadow: inset 0 0 10px -2px rgba(0, 0, 0, 0.2);
}

bot-command {
  display: flex;
  flex-direction: column;
  border: none;
  background: #292841;
  border-radius: 15px;
  box-shadow: 6.01px 6.01px 9px #242339, -6.01px -6.01px 9px #2E2D49;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
  }

  &.hidden {
    display: none;
  }

  margin: 1rem;

  width: 100%;

  & .card__header {
    display: flex;
    align-items: stretch;
    width: 100%;


    & div:nth-child(1),
    & div:nth-child(3) {
      width: 15%;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bolder;
      border-radius: 15px 0px 0px 0px;
    }

    & div:nth-child(3) {
      border-radius: 0px 15px 0px 0px;

      img {
        height: 20px;
        max-height: 20px;
        fill: #fff;
        color: #fff;

      }
    }

    & div:nth-child(2) {
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 0;
      background: rgba(0, 0, 0, 0.1);
    }

    & h3 {
      font-weight: normal;
      font-size: 1.1rem;
      margin: 0;
    }

    & img {
      height: 30px;
      max-height: 30px;
      fill: #fff;
      color: #fff;
    }
  }

  & .card__body {
    padding: 0.5rem 1rem;
    padding-top: 0;
    color: #ccc;
    text-align: left;
  }

  & .card__footer {
    padding: 0.5rem 0;

    border-radius: 0px 0px 15px 15px;

    &:not(:empty) {
      background: rgba(0, 0, 0, 0.05);
    }

    small {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

footer {
  padding: 2rem 0;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

/** Place here all Media Queries and it's styles.**/

/** Default Style. Don't change. (320px) **/
@media screen and  (max-width: 1) {}

/** Extra Small Devices, Phones (480px) **/
@media screen and (min-width : 30dvw) {

}

/** Medium Screens, Tablets (601px) **/
@media screen and (min-width: 37.56255em) {
  bot-command {
    width: calc((100% / 2) - 2rem);
  }
}

/** Medium Devices, Desktops (992px)**/
@media screen and (min-width: 62em) {
  bot-command {
    width: calc((100% / 3) - 3rem);
  }
}

/** HD Screen, Large Devices, Wide Screens (1200px) **/
@media screen and (min-width: 75em) {
  bot-command {
    width: calc((100% / 4) - 4rem);
  }
}

/** Full HD Screen, Large Devices, Wide Screens (1920px) **/
@media screen and (min-width: 120em) {
  bot-command {
    width: calc((100% / 5) - 5rem);
  }
}

/** Retina Screen , Large Devices, Wide Screens(2560px) **/
@media screen and (min-width: 160em) {
  bot-command {
    width: calc((100% / 6) - 6rem);
  }
}

/** 4k Screens, Large Devices, Wide Screens (3840px) **/
@media screen and (min-width: 240em) {

}

/** 5k Screens, Large Devices, Wide Screens (5120px) **/
@media screen and (min-width: 312.15em) {

}

/** CSS for Printing **/
@media print {

}